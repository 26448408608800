.body {
    overflow: auto;
    width: 100%;
    height: 100%;
}

.map {
    width: 100%;
    height: 100%;
}

.hidden {
    display: none;
}

.location {
    width: 40px !important;
    height: 40px !important;
    position: absolute !important;
    top: 80px;
    right: 50px;
}

.styleChooserBody {
    padding: 0em 1em 3em;
    background: rgba(255, 255, 255, 0.5);
    width: 120px;
    height: 340px;
    position: absolute;
    bottom: 40px;
    right: 20px;
}

.partitionToggleText {
    width: 70px;
    font-size: 11px;
    word-wrap: break-word;
    display: inline-block;
    vertical-align: middle;
}

.clearButton .partitionToggleText {
    word-wrap: break-word;
    display: inline-block;
    vertical-align: middle;
}

.marker-container {
    text-align: center;
    font-family: Arial, sans-serif;
}
.marker-label {
    color: #333;
    font-size: 8px;
    font-weight: bold;
}