.body {
    width: 500px;
    height: 85%;
    position: absolute;
    top: 100px;
    margin-left: 20px;
    overflow: scroll !important;
}

.uidSearchBody {
    position: absolute;
    top: 100px;
    margin-left: 20px;
}

.uidSearch {
    width: 250px;
}

.content {
    height: 100%;
}

.cell {
    padding-left: 10px !important;
    padding-right: 0px !important;
}

.geometryCell {
    padding-left: 10px !important;
    padding-right: 0px !important;
    height: 100px;
}

.tableContent {
    height: 100%;
    width: 100%;
    overflow: auto;
    white-space: pre;
}

.kalmanButton {
    text-transform: none !important;
    height: 50px;
}

.chartMenu {
    margin-left: 20px;
}

.chartContainer {
    height: 600px;
    width: 1100px;
}

.infoRow {
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    box-sizing: border-box;
}

.infoBox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.materialSlider {
    height: 30px;
    padding-left: 20px;
}