.body {
    width: 525px;
    position: absolute;
    top: 80px;
    margin-left: 20px;
    /*background-color: white;*/
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-height: 90%;
}

.inputCard {
    width: 100%;
    margin-bottom: 20px;
    flex-shrink: 0;
}

.inputCardContent {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    box-sizing: border-box;
}

.inputRow {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: flex-start;
}

.vehiclePropsRow {
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    box-sizing: border-box;
    margin-bottom: 15px;
}

.truckPropsExpansionPanel {
    padding-left: 20px !important;
    padding-right: 20px !important;
    position: relative;
    overflow: auto;
}

.truckPropsTable {
    position: absolute;
    overflow: auto;
    margin-top: 10px;
    width: 95% !important;
}

.truckPropsCell {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.resetDiv {
    text-align: right;
    height: 200px; /* this is the expansion panel height */
    width: 100%;
}

.recalculateButton {
    text-transform: none !important;
    max-height: 35px !important;
    min-width: 100px !important;
    padding: 5px 8px !important;
}

.resetButton {
    text-transform: none !important;
    min-height: 10px !important;
    padding: 5px 8px !important;
    transform: translate(20%, -20%);
    position: absolute;
    z-index: 10;
}

.vehicleProp {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 10px;
}

.targetUrlRow {
    width: 100%;
    flex-direction: row;
    display: flex;
    margin-bottom: 15px;
    align-items: center;
}

.controlRow {
    width: 100%;
    flex-direction: row;
    display: flex;
    margin-top: 10px;
    margin-bottom: 15px;
    justify-content: space-between;
}

.image {
    width: 30px;
    height: 35px;
    object-fit: contain;
    margin-right: 15px;
}

.routeRow {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
}

.chartContainer {
    height: 300px;
    width: 525px;
}

.routeCard {
    width: 100%;
}

.routeCardContent {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    box-sizing: border-box;
}

.search {
    width: 100%;
    background-color: #ffffff;
}

.route {
    height: 100%;
    box-sizing: border-box;
    overflow: scroll;
}

.geosuggest {
    width: 300px;
}

.geosuggest__suggests--hidden {
    max-height: 0;
    overflow: hidden;
    border-width: 0;
}

.geosuggest__item--active {
    background: #267dc0;
    color: #fff;
}

.marker {
    display: block;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    padding: 0;
}

.expansionPanel{
    width: 100%;
}

.timeout{
    height: 36px;
    width: 25%;
    display: table;
    overflow: hidden;
    padding-right: 5%;
}

.timeoutText {
    height: 100%;
    display: table-cell;
    vertical-align: middle;
    text-align: right;
    padding-right: 15%;
}

.timeoutInput {
    height: 100%;
    width: 80%;
    font-size: small;
    display: table-cell;
    vertical-align: middle;
    text-align: right;
}

.agents{
    height: 36px;
    width: 45%;
    display: table;
    overflow: hidden;
}

.agentsText {
    height: 100%;
    display: inline;
    vertical-align: middle;
    text-align: right;
    padding-right: 5%;
}

.agentsInput {
    height: 100%;
    width: 10%;
    font-size: small;
    display: inline;
    vertical-align: middle;
    text-align: right;
}

.routingLayers{
    height: 75px;
    width: 100%;
    display: table;
    overflow: hidden;
}

.routingLayersText {
    height: 100%;
    width: 10%;
    display: inline;
    vertical-align: middle;
    text-align: right;
    padding-right: 5%;
}

.routingLayersInput {
    height: 30px;
    width: 90%;
    font-size: small;
    display: inline;
    vertical-align: middle;
}

.eventRouting {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
}

.parameters {
    width: 100%;
    flex-direction: column;
    display: flex;
}

.toggleRow {
    width: 100%;
    flex-direction: row;
    display: flex;
    margin-top: 10px;
    margin-bottom: 15px;
    justify-content: left;
}

.destinationKnob {
    flex-direction: row;
    display: flex;
    justify-content: left;
}

.toggleText {
    height: 100%;
    font-size: 14px;
    display: inline-block;
    vertical-align: middle;
    text-align: right;
    padding-top: 1%;
    padding-right: 2%;
}

.materialSlider {
    height: 30px;
}

.dotsMenuButton {
    transform: translateY(-15px);
}

.dotsMenuItem {
    margin-left: 10px;
    margin-bottom: 10px;
}

/* text pop up when hovering over truck params */
.hoverText {
    width: 180px;
    font-size: 12px;
}

.infoSVG {
    padding-top: 2px;
    font-size: 12px;
}

.route {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow: scroll;
}

.infoBox {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}

.accelerationRateButton {
    margin-right: 10px !important;
    min-width: 37px !important;
    padding-left: 4px !important;
    padding-right: 4px !important;
}
