body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.geosuggest__suggests--hidden {
    max-height: 0;
    overflow: hidden;
    border-width: 0;
}

.geosuggest__item--active {
    background: #267dc0;
    color: #fff;
}

.geosuggest {
    width: 100%;
}
.App_body__awDgw {
    width: 100vw;
    height: 100vh;
}

.App_splashScreen__1Y1fF {
    position: absolute !important;
    top: 0px !important;
    height: 100% !important;
    width: auto !important;
    left: 50% !important;
    -webkit-transform: translate(-50%, 0) !important;
            transform: translate(-50%, 0) !important;
}

.App_splashScreenBackground__2UgZP {
    background-color: #286396;
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
}

.App_splashScreenRadialBackground__2kx3Z {
    background-image: radial-gradient(closest-side, #4c91d0, #286396);
    position: absolute;
    top: 0px;
    width: 50%;
    /*padding-left: 100%;*/
    height: 100%;
    -webkit-transform: translate(50%, 0) !important;
            transform: translate(50%, 0) !important;
}
.MapContainer_body__1YyGo {
    overflow: auto;
    width: 100%;
    height: 100%;
}

.MapContainer_map__kOOQi {
    width: 100%;
    height: 100%;
}

.MapContainer_hidden__28y2B {
    display: none;
}

.MapContainer_location__3Gjm- {
    width: 40px !important;
    height: 40px !important;
    position: absolute !important;
    top: 80px;
    right: 50px;
}

.MapContainer_styleChooserBody__2Ilsj {
    padding: 0em 1em 3em;
    background: rgba(255, 255, 255, 0.5);
    width: 120px;
    height: 340px;
    position: absolute;
    bottom: 40px;
    right: 20px;
}

.MapContainer_partitionToggleText__2OQ2a {
    width: 70px;
    font-size: 11px;
    word-wrap: break-word;
    display: inline-block;
    vertical-align: middle;
}

.MapContainer_clearButton__NE_fv .MapContainer_partitionToggleText__2OQ2a {
    word-wrap: break-word;
    display: inline-block;
    vertical-align: middle;
}

.MapContainer_marker-container__3tolS {
    text-align: center;
    font-family: Arial, sans-serif;
}
.MapContainer_marker-label__208ce {
    color: #333;
    font-size: 8px;
    font-weight: bold;
}
.Search_body__3sZfZ {
    /*width: 300px;*/
    /*height: 80px;*/
    position: absolute;
    top: 100px;
    margin-left: 20px;
}

.NearestNeighbour_body__2TDDy {
    width: 500px;
    height: 85%;
    position: absolute;
    top: 100px;
    margin-left: 20px;
    overflow: scroll !important;
}

.NearestNeighbour_uidSearchBody__NSbGO {
    position: absolute;
    top: 100px;
    margin-left: 20px;
}

.NearestNeighbour_uidSearch__1UBH_ {
    width: 250px;
}

.NearestNeighbour_content__IcGZ9 {
    height: 100%;
}

.NearestNeighbour_cell__OWQnM {
    padding-left: 10px !important;
    padding-right: 0px !important;
}

.NearestNeighbour_geometryCell__8h0_j {
    padding-left: 10px !important;
    padding-right: 0px !important;
    height: 100px;
}

.NearestNeighbour_tableContent__1_ucE {
    height: 100%;
    width: 100%;
    overflow: auto;
    white-space: pre;
}

.NearestNeighbour_kalmanButton__1GXeX {
    text-transform: none !important;
    height: 50px;
}

.NearestNeighbour_chartMenu__3yBir {
    margin-left: 20px;
}

.NearestNeighbour_chartContainer__2MRET {
    height: 600px;
    width: 1100px;
}

.NearestNeighbour_infoRow__3vj-C {
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    box-sizing: border-box;
}

.NearestNeighbour_infoBox__29JGP {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.NearestNeighbour_materialSlider__Zj-B8 {
    height: 30px;
    padding-left: 20px;
}
.MapData_body__3hSjh {
    width: 400px;
    height: 50%;
    position: absolute;
    top: 100px;
    margin-left: 20px;
    overflow: scroll;
}

.MapData_inputCardContent___q63J {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    box-sizing: border-box;
    overflow: scroll;
}

.MapData_search__kMJzF {
    width: 100%;
    background-color: #ffffff;
}

.MapData_expansionPanel__3m82_{
    width: 100%;
}

.MapData_cell__2lsLP {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.MapData_geometryCell__2Y0wp {
    padding-left: 0px !important;
    padding-right: 0px !important;
    height: 100px;
}

.MapData_tableContent__BqLwq {
    height: 100%;
    width: 100%;
    overflow: auto;
    white-space: pre;
}

.Tour_body__2MgDj {
    width: 250px;
    height: 15%;
    position: absolute;
    top: 200px;
    margin-left: 20px;
    overflow: scroll !important;
}

.Tour_content__2ba6X {
    height: 100%;
}

.Tour_vehiclePicker__aIpm5 {
    position: absolute;
    top: 100px;
    margin-left: 20px;
    background-color: white;
}

.Tour_uploadButton__1eowt {
    position: absolute;
    top: 150px;
    margin-left: 20px;
}

.Tour_dayPicker__36QOJ {
    width: 100%;
    flex-direction: row;
    display: flex;
    margin-bottom: 15px;
    align-items: center;
}
.Routing_body__9k5t4 {
    width: 525px;
    position: absolute;
    top: 80px;
    margin-left: 20px;
    /*background-color: white;*/
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-height: 90%;
}

.Routing_inputCard__3ANl3 {
    width: 100%;
    margin-bottom: 20px;
    flex-shrink: 0;
}

.Routing_inputCardContent__qCXtH {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    box-sizing: border-box;
}

.Routing_inputRow__3YEjy {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: flex-start;
}

.Routing_vehiclePropsRow__3rfsH {
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    box-sizing: border-box;
    margin-bottom: 15px;
}

.Routing_truckPropsExpansionPanel__3qcOa {
    padding-left: 20px !important;
    padding-right: 20px !important;
    position: relative;
    overflow: auto;
}

.Routing_truckPropsTable__U2J4f {
    position: absolute;
    overflow: auto;
    margin-top: 10px;
    width: 95% !important;
}

.Routing_truckPropsCell__1rSsg {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.Routing_resetDiv__3gvEr {
    text-align: right;
    height: 200px; /* this is the expansion panel height */
    width: 100%;
}

.Routing_recalculateButton__2GDAY {
    text-transform: none !important;
    max-height: 35px !important;
    min-width: 100px !important;
    padding: 5px 8px !important;
}

.Routing_resetButton__28Nl7 {
    text-transform: none !important;
    min-height: 10px !important;
    padding: 5px 8px !important;
    -webkit-transform: translate(20%, -20%);
            transform: translate(20%, -20%);
    position: absolute;
    z-index: 10;
}

.Routing_vehicleProp__13iFO {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 10px;
}

.Routing_targetUrlRow__Kc1GP {
    width: 100%;
    flex-direction: row;
    display: flex;
    margin-bottom: 15px;
    align-items: center;
}

.Routing_controlRow__3kLk1 {
    width: 100%;
    flex-direction: row;
    display: flex;
    margin-top: 10px;
    margin-bottom: 15px;
    justify-content: space-between;
}

.Routing_image__1Uso3 {
    width: 30px;
    height: 35px;
    object-fit: contain;
    margin-right: 15px;
}

.Routing_routeRow__HsP6n {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
}

.Routing_chartContainer__3Qe29 {
    height: 300px;
    width: 525px;
}

.Routing_routeCard__2c1l_ {
    width: 100%;
}

.Routing_routeCardContent__3xdcW {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    box-sizing: border-box;
}

.Routing_search__383mX {
    width: 100%;
    background-color: #ffffff;
}

.Routing_route__2H9uy {
    height: 100%;
    box-sizing: border-box;
    overflow: scroll;
}

.Routing_geosuggest__1WCmh {
    width: 300px;
}

.Routing_geosuggest__suggests--hidden__1qdSy {
    max-height: 0;
    overflow: hidden;
    border-width: 0;
}

.Routing_geosuggest__item--active__uFtOj {
    background: #267dc0;
    color: #fff;
}

.Routing_marker__1pO8t {
    display: block;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    padding: 0;
}

.Routing_expansionPanel__1AtF7{
    width: 100%;
}

.Routing_timeout__10XO9{
    height: 36px;
    width: 25%;
    display: table;
    overflow: hidden;
    padding-right: 5%;
}

.Routing_timeoutText__2MGHD {
    height: 100%;
    display: table-cell;
    vertical-align: middle;
    text-align: right;
    padding-right: 15%;
}

.Routing_timeoutInput__2vXGk {
    height: 100%;
    width: 80%;
    font-size: small;
    display: table-cell;
    vertical-align: middle;
    text-align: right;
}

.Routing_agents__3JpyX{
    height: 36px;
    width: 45%;
    display: table;
    overflow: hidden;
}

.Routing_agentsText__1Z2xs {
    height: 100%;
    display: inline;
    vertical-align: middle;
    text-align: right;
    padding-right: 5%;
}

.Routing_agentsInput__Q8BPp {
    height: 100%;
    width: 10%;
    font-size: small;
    display: inline;
    vertical-align: middle;
    text-align: right;
}

.Routing_routingLayers__2TxLK{
    height: 75px;
    width: 100%;
    display: table;
    overflow: hidden;
}

.Routing_routingLayersText__2gq3s {
    height: 100%;
    width: 10%;
    display: inline;
    vertical-align: middle;
    text-align: right;
    padding-right: 5%;
}

.Routing_routingLayersInput__3Kfvn {
    height: 30px;
    width: 90%;
    font-size: small;
    display: inline;
    vertical-align: middle;
}

.Routing_eventRouting__2US9H {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
}

.Routing_parameters__2mBN2 {
    width: 100%;
    flex-direction: column;
    display: flex;
}

.Routing_toggleRow__2HKEk {
    width: 100%;
    flex-direction: row;
    display: flex;
    margin-top: 10px;
    margin-bottom: 15px;
    justify-content: left;
}

.Routing_destinationKnob__vEDtz {
    flex-direction: row;
    display: flex;
    justify-content: left;
}

.Routing_toggleText__SZ_SU {
    height: 100%;
    font-size: 14px;
    display: inline-block;
    vertical-align: middle;
    text-align: right;
    padding-top: 1%;
    padding-right: 2%;
}

.Routing_materialSlider__3Bens {
    height: 30px;
}

.Routing_dotsMenuButton__1En88 {
    -webkit-transform: translateY(-15px);
            transform: translateY(-15px);
}

.Routing_dotsMenuItem__3SW1r {
    margin-left: 10px;
    margin-bottom: 10px;
}

/* text pop up when hovering over truck params */
.Routing_hoverText__1QMOd {
    width: 180px;
    font-size: 12px;
}

.Routing_infoSVG__gmOtO {
    padding-top: 2px;
    font-size: 12px;
}

.Routing_route__2H9uy {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow: scroll;
}

.Routing_infoBox__30C67 {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}

.Routing_accelerationRateButton__1K8to {
    margin-right: 10px !important;
    min-width: 37px !important;
    padding-left: 4px !important;
    padding-right: 4px !important;
}

.mapboxgl-ctrl-top-right .mapboxgl-ctrl {
    margin-top: 80px !important;
    margin-right: 150px !important;
}

.mapboxgl-ctrl-group > button {
    width: 50px !important;
    height: 50px !important;
}
.FlowData_body__IuTt9 {
    width: 350px;
    height: 100px;
    position: absolute;
    top: 100px;
    margin-left: 20px;
    overflow: scroll;
}

.FlowData_inputCardContent__1m3CQ {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    box-sizing: border-box;
    overflow: scroll;
}


.FlowData_search__y7bCg {
    width: 100%;
    background-color: #ffffff;
}
.ParserStatus_head__1GjkY {
    position: relative;
    top: 64px;
    margin-left: 20px;
}

table.ParserStatus_statusTable__caTVm {
    border-collapse: collapse;
    margin-bottom: 1rem;
    border: 1px solid black;
}

table.ParserStatus_statusTable__caTVm, th.ParserStatus_statusTable__caTVm, td.ParserStatus_statusTable__caTVm {
    border: 1px solid black;
    padding: 5px;
    text-align: left;
}

.ParserStatus_wrapCollapsible__3FqN5 {
    width: 100%;
    margin-bottom: 0rem;
    border-bottom: 1px solid #bcbcbc;
}

.ParserStatus_parseButton__2ZyID {
    text-transform: none !important;
    text-align: center;
    padding: 0px !important;
    margin-left: 40px !important;
    height: 24px;
    width: 40px;
}

.ParserStatus_customParseButton__GdgLc {
    text-transform: none !important;
    text-align: center;
    padding: 0px !important;
    height: 32px;
    width: 40px;
}

.ParserStatus_customRegionField__3T7pw {
    margin-top: -10px !important;
    margin-right: 20px !important;
    width: 100px;
}

.ParserStatus_customRoadSideSelect__28BO- {
    width: 100px !important;
    margin-right: 20px !important;
    margin-top: -14px !important;
}

.ParserStatus_spatialServiceStrand__172xf {
    margin-left: 5px !important;
    margin-right: 5px !important;
}

.ParserStatus_spatialServiceRow__3gAX7 {
    margin-top: 30px;
    margin-bottom: 30px;
    width: 100%;
}

.ParserStatus_spatialServiceSelect__3G5I3 {
    width: 200px !important;
    margin-right: 20px !important;
    margin-top: -14px !important;
}
.body {
    width: 350px;
    height: 400px;
    position: absolute;
    top: 100px;
    margin-left: 20px;
}

.textField {
    width: 100%;
}

.inputCardContent {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    box-sizing: border-box;
}

.button {

}

.EdgeCard_body__1u0sM {
    width: 100%;
    height: 50%;
    position: absolute;
    bottom: 35px;
}

.EdgeCard_inputCardContent__2H8X5 {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    box-sizing: border-box;
}

.EdgeCard_titleRow__3n2_w {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
}

.EdgeCard_vehiclePropsRow__1Ni78 {
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    box-sizing: border-box;
}

.EdgeCard_truckPropsRow__2UtFu {
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    box-sizing: border-box;
    overflow-y: scroll;
}

.EdgeCard_vehicleProp__3obih {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.EdgeCard_divider__sxAAe {
    width: 100%;
    box-sizing: border-box;
}

.EdgeCard_graph__1ztle {
    width: 100%;
}
.UturnAnalyser_body__D6ypN {
    width: 450px;
    height: 200px;
    position: absolute;
    top: 100px;
    margin-left: 20px;
}

.UturnAnalyser_textField__55hy0 {
    width: 100%;
}

.UturnAnalyser_inputCardContent__1Ixnj {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    box-sizing: border-box;
}

.UturnAnalyser_datePicker__1Fp-s {
    width: 100%;
    flex-direction: row;
    display: flex;
    margin-bottom: 15px;
    align-items: center;
}

.LoginContainer_body__MHbQl {
}

.LoginContainer_buttonProgress__15QuK {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
}
