.body {
    width: 100%;
    height: 50%;
    position: absolute;
    bottom: 35px;
}

.inputCardContent {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    box-sizing: border-box;
}

.titleRow {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
}

.vehiclePropsRow {
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    box-sizing: border-box;
}

.truckPropsRow {
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    box-sizing: border-box;
    overflow-y: scroll;
}

.vehicleProp {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.divider {
    width: 100%;
    box-sizing: border-box;
}

.graph {
    width: 100%;
}