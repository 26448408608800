.body {
    width: 400px;
    height: 50%;
    position: absolute;
    top: 100px;
    margin-left: 20px;
    overflow: scroll;
}

.inputCardContent {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    box-sizing: border-box;
    overflow: scroll;
}

.search {
    width: 100%;
    background-color: #ffffff;
}

.expansionPanel{
    width: 100%;
}

.cell {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.geometryCell {
    padding-left: 0px !important;
    padding-right: 0px !important;
    height: 100px;
}

.tableContent {
    height: 100%;
    width: 100%;
    overflow: auto;
    white-space: pre;
}
