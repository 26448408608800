.head {
    position: relative;
    top: 64px;
    margin-left: 20px;
}

table.statusTable {
    border-collapse: collapse;
    margin-bottom: 1rem;
    border: 1px solid black;
}

table.statusTable, th.statusTable, td.statusTable {
    border: 1px solid black;
    padding: 5px;
    text-align: left;
}

.wrapCollapsible {
    width: 100%;
    margin-bottom: 0rem;
    border-bottom: 1px solid #bcbcbc;
}

.parseButton {
    text-transform: none !important;
    text-align: center;
    padding: 0px !important;
    margin-left: 40px !important;
    height: 24px;
    width: 40px;
}

.customParseButton {
    text-transform: none !important;
    text-align: center;
    padding: 0px !important;
    height: 32px;
    width: 40px;
}

.customRegionField {
    margin-top: -10px !important;
    margin-right: 20px !important;
    width: 100px;
}

.customRoadSideSelect {
    width: 100px !important;
    margin-right: 20px !important;
    margin-top: -14px !important;
}

.spatialServiceStrand {
    margin-left: 5px !important;
    margin-right: 5px !important;
}

.spatialServiceRow {
    margin-top: 30px;
    margin-bottom: 30px;
    width: 100%;
}

.spatialServiceSelect {
    width: 200px !important;
    margin-right: 20px !important;
    margin-top: -14px !important;
}