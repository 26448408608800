.body {
    width: 250px;
    height: 15%;
    position: absolute;
    top: 200px;
    margin-left: 20px;
    overflow: scroll !important;
}

.content {
    height: 100%;
}

.vehiclePicker {
    position: absolute;
    top: 100px;
    margin-left: 20px;
    background-color: white;
}

.uploadButton {
    position: absolute;
    top: 150px;
    margin-left: 20px;
}

.dayPicker {
    width: 100%;
    flex-direction: row;
    display: flex;
    margin-bottom: 15px;
    align-items: center;
}