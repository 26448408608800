.body {
    width: 350px;
    height: 100px;
    position: absolute;
    top: 100px;
    margin-left: 20px;
    overflow: scroll;
}

.inputCardContent {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    box-sizing: border-box;
    overflow: scroll;
}


.search {
    width: 100%;
    background-color: #ffffff;
}