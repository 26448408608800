.body {
    width: 100vw;
    height: 100vh;
}

.splashScreen {
    position: absolute !important;
    top: 0px !important;
    height: 100% !important;
    width: auto !important;
    left: 50% !important;
    transform: translate(-50%, 0) !important;
}

.splashScreenBackground {
    background-color: #286396;
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
}

.splashScreenRadialBackground {
    background-image: radial-gradient(closest-side, #4c91d0, #286396);
    position: absolute;
    top: 0px;
    width: 50%;
    /*padding-left: 100%;*/
    height: 100%;
    transform: translate(50%, 0) !important;
}