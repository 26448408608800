.body {
    width: 450px;
    height: 200px;
    position: absolute;
    top: 100px;
    margin-left: 20px;
}

.textField {
    width: 100%;
}

.inputCardContent {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    box-sizing: border-box;
}

.datePicker {
    width: 100%;
    flex-direction: row;
    display: flex;
    margin-bottom: 15px;
    align-items: center;
}
